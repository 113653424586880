#tos_box {
    height: 40vw;
    overflow: scroll;
    background-color: var(--background-color);
    font-color: $primary-text-color;   
}

#tos_content p {
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
}

#tos_content a {
    color: #8c8dff;
}
